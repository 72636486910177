import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, Routes } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from './services/users.service';
import { OrdersService } from './services/orders.service';
import { AppService } from './app.service';
import { GuestLogin, Ordenes, Catalog } from './app.models';
import { FreeShippingDialogComponent } from './ free-shipping/ free-shipping-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading: boolean = false;
  public settings: Settings;
  constructor(public appSettings: AppSettings,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public translate: TranslateService,
    public usersService: UsersService,
    public ordersService: OrdersService,
    public appService: AppService,
    public dialog: MatDialog,
    private cookieService: CookieService
    // public catalogsService:CatalogsService,
  ) {
    this.settings = this.appSettings.settings;
    translate.addLangs(['en', 'es']);
    var default_country=window.location.hostname.indexOf("us.") > -1 || window.location.hostname.indexOf("devusa.") > -1?"USA":"MX";
    this.appService.setCountry(environment.country=== undefined ? default_country: environment.country);
    var languaje=this.appService.getCountryLanguaje();
    translate.setDefaultLang(languaje);
    translate.use(languaje);
    
  }

  ngOnInit() {
    // this.sessionValidateBDvsCognito();
    // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh  

    this.translate.get('PAGES.LOGIN').subscribe((translation: string) => {
        this.appService.Data.userName = translation; 
    });
    if(localStorage.getItem("userLoggedDataBD" + environment.ambiente) != undefined){
      this.appService.checkLoggedUser();
    }

    if (!localStorage.getItem('token' + environment.ambiente) || localStorage.getItem('token' + environment.ambiente) == "undefined") {
      this.appService.getToken();
    } else {
      this.getOrder();
    }
    
    // this.getCategories();
  }

  getOrder() {
    if (!localStorage.getItem('token' + environment.ambiente) || localStorage.getItem('token' + environment.ambiente) == "undefined") {
      this.usersService.guestLogin().subscribe((res: GuestLogin) => {
        const response: GuestLogin = {
          token: res.token
        };
        localStorage.setItem('token' + environment.ambiente, response.token);
        this.getBytoken();
      })
    } else {
      this.getBytoken();  
    }
  }

  public getBytoken() {
    this.ordersService.getOrdersBytoken().subscribe((res: Ordenes) => {
      if (res.order?.products) {
        this.appService.Data.totalPrice = res.order.amount;
        this.appService.Data.totalCartCount = res.order.quantity;
        this.appService.Data.shippingPercentage = res.order.shippingPercentage;
        this.appService.Data.remainingShippingAmount = res.order.remainingShippingAmount;
        this.appService.Data.isFreeShipping = res.order.isFreeShipping;
        this.appService.Data.freeShippingAvailable = res.order.freeShippingAvailable;
        this.appService.Data.cartList = res.order.products;
        this.appService.Data.minimumPieces = res.order.minimumPieces;
      }
      this.appService.Data.order = res.order;
       this.validCP();
    });

  }

  public validCP() {
    const regex = /^\/products.*/;
    const { Data } = this.appService;
  
    const isCartOrProductPage = window.location.pathname === '/cart' || regex.test(window.location.pathname) || window.location.pathname === '/';
    const isDeliveryAddressEmpty = !Data.order?.deliveryAddress?.zipcode;
  
    if (isCartOrProductPage && isDeliveryAddressEmpty) {
       this.dialog.open(FreeShippingDialogComponent, {});
    }
  }

  // public getCategories(){  
  //   if(this.appService.Data.categories.length == 0) { 
  //     this.catalogsService.getCatalogsByLabel('Categorias').subscribe((data:Catalog) => {
  //       console.log('servicio')
  //       this.appService.Data.categories = data['catalog'][0].values; ;
  //     });
  //   }
  // }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      }
    })
  }

  // sessionValidateBDvsCognito(){
  //   let lastAuthUser= localStorage.getItem("CognitoIdentityServiceProvider."+environment.cognito.userPoolWebClientId+".LastAuthUser");
  //   /* Se valida si existe el objeto de BD del usuario logueado en localstorage */
  //   if(localStorage.getItem("userLoggedDataBD" + environment.ambiente) !== null){
  //     /* Se valida si existe el objeto de la sesión de cognito */
  //     if(lastAuthUser !== null && lastAuthUser !== undefined){
  //       /* Si existe objeto de BD y sesión de cognito */
  //       console.log("Existe sesión");
  //     }else{
  //       /* Como no existe, pero si estaba logueado, se borra variable de localstorage */
  //       console.log("No existe sesión");
  //       localStorage.removeItem("userLoggedDataBD");
  //     }
  //   }
  // }
}
