import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuestLogin,Ordenes } from '../.../../../app/app.models';
import { OrdersService } from '../services/orders.service';
import { AppService } from '../app.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from '../services/users.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-free-shipping-dialog',
  templateUrl: './ free-shipping-dialog.component.html',
  styleUrls: ['./ free-shipping-dialog.component.scss']
})
export class FreeShippingDialogComponent implements OnInit {

  cp: any = null;
  error: boolean = false;
  mensaje:string='El Código Postal no es correcto';
  version: string=environment.version;
  public couponTypeSelected:string = "productCoupon";
  public types: any[] = [{ label: "color", value: "color" }, { label: "otro", value: "radiobutton" },];
  constructor(public dialogRef: MatDialogRef<FreeShippingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ordersService: OrdersService,
    public appService:AppService, 
    public fb: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    public usersService: UsersService,) { }

  ngOnInit(): void {
  }

  public validCP() {
    if(this.cp != ""){
      this.error = false;
      const params = {
        "deliveryAddress":{
        "zipcode": this.cp
        }
      }
      if(localStorage.getItem('token' + environment.ambiente) == null || localStorage.getItem('token' + environment.ambiente) == undefined){
        this.usersService.guestLogin().subscribe((res: GuestLogin) => {
          const response: GuestLogin = {
            token: res.token
          };
          if(response.token != undefined){
            localStorage.setItem('token' + environment.ambiente, response.token);
            this.getBytoken(true);
          }
        });
      }else{
        try{
          this.ordersService.deliveryAddress(params).subscribe((res: any) => {
            if(res.datos[0].statusText ==='OK' && res.datos[0].status !== 400){
              this.getBytoken(false);
              this.dialogRef.close();
            }else{
              if(res.message != null && res.message != undefined){
                this.mensaje= res.message;
              }else{
                this.mensaje= '101: '+ this.mensaje;
              }
              this.error = true;
            }
          })
        }catch(error){
          this.mensaje= '102: '+error;
          this.error = true;
        }
        
      }
    }
  }

  public getBytoken(error) {
    try{
      this.ordersService.getOrdersBytoken().subscribe((res: Ordenes) => {
        if (res.order?.products) {
          this.appService.Data.totalPrice = res.order.amount;
          this.appService.Data.totalCartCount = res.order.quantity;
          this.appService.Data.shippingPercentage = res.order.shippingPercentage;
          this.appService.Data.remainingShippingAmount = res.order.remainingShippingAmount;
          this.appService.Data.isFreeShipping = res.order.isFreeShipping;
          this.appService.Data.freeShippingAvailable = res.order.freeShippingAvailable;
          this.appService.Data.cartList = res.order.products;
          this.appService.Data.minimumPieces = res.order.minimumPieces;
        }
        this.appService.Data.order = res.order;
        
        if(error){
          this.mensaje= 'Ocurrió un error, por favor, vuelve a intentarlo.';
          this.error = true;
        }
      });  
    }catch(err){
      this.mensaje= '103: '+ err;
      this.error = true;
    }
    
  }

  public valcp (event) 
  {
    this.cp = event.target.value;

  }

  public onSubmit() {
    if (this.cp.valid) {
      this.dialogRef.close();
    }
  }
  public close(){
    this.dialogRef.close();
  }

}
