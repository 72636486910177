import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CognitoService } from '../../../app/services/cognito.service';
import { AppService } from '../../../app/app.service';


// import { environment } from '../../../environments/environment';
// import { CognitoService } from '../../../app/services/cognito.service';
// import { AppService } from '../../../app/app.service';


@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private cognitoService: CognitoService, private appService: AppService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('payment/status')) {
      // this.spinner.show();
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('Evento:', event.status);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if ((error.status === 401 || error.status === 0) && !req.headers.has('X-Retry')) {
          // Convert the fetchAuthSession promise to an Observable using from
          return from(this.appService.checkLoggedUser()).pipe(
            switchMap(result => {
              if (result) {
                return from(this.cognitoService.getSession()).pipe(
                  switchMap(session => {
                    // Assuming you get the token from the session object correctly
                    const newToken = session.tokens.accessToken.toString();

                    let headers_ = new HttpHeaders().set('content-type', 'application/json');
                    headers_.append('Authorization', newToken)
                    headers_.append('X-Retry', 'true')
                    headers_ = headers_.append('Authorization', newToken);
                    // headers_ = headers_.append('Country-Code', 'MX'); 
                    const retryRequest = req.clone({ headers: headers_ });
                    return next.handle(retryRequest).pipe(
                      delay(1000) // Ajusta el tiempo según sea necesario
                    );
                  }),
                  catchError(refreshError => {
                    // Handle possible errors from refreshing the token
                    return throwError(refreshError);
                  }))
              } else {
                return throwError("Error al renovar el token");
              }
            }),
            catchError(refreshError => {
              // Handle possible errors from refreshing the token
              return throwError(refreshError);
            })
          )
        }
        return throwError(error);
      }),
    );
  }
}
