import { Component, OnInit, Input} from '@angular/core';
import { CatalogsService } from '../../../services/catalogs.service';
import { Product, Category, ProductArray, Catalog } from "../../../app.models";
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {
  public categories:Category[];

  constructor( private catalogsService: CatalogsService, 
    public appService:AppService) { 
   }

  ngOnInit() {
    this.getCategories();

   }

   public getCategories(){   
    if(this.appService.Data.categories.length == 0) { 
      console.log('servicio menu')
      this.catalogsService.getCatalogsByLabel('Categorias').subscribe((data:Catalog) => {
        this.categories = data['catalog'][0].values; ;
        this.appService.Data.categories = data['catalog'][0].values; ;
      });
    }
    else{
      console.log('consulta menu')
      this.categories = this.appService.Data.categories;
    }
  }

  // openMegaMenu(){
  //   let pane = document.getElementsByClassName('cdk-overlay-pane');
  //   [].forEach.call(pane, function (el) {
  //       if(el.children.length > 0){
  //         if(el.children[0].classList.contains('mega-menu')){
  //           el.classList.add('mega-menu-pane');
  //         }
  //       }        
  //   });
  // }

}
