import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppService } from '../../../app.service';
import { Product, Value, CombinationArray } from '../../../app.models';
import { OrdersService } from '../../../services/orders.service';
import { ProductsService } from '../../../services/products.service';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDialogComponent implements OnInit {
  public config: SwiperConfigInterface = {};
  public validVariants: Value[] = [];
  public variants : any = [];
  constructor(public appService: AppService,
    public dialogRef: MatDialogRef<ProductDialogComponent>,
    private productsService: ProductsService,
    public ordersService: OrdersService,
    @Inject(MAT_DIALOG_DATA) public product: Product) { }

  ngOnInit() {
    this.product.variants.forEach(variant => {
      variant.values.forEach(value => {
        value.default = false;
      })
    })
    if (this.product?.variants.length>0){
      this.variants = [this.product?.variants[0]];
    }

    
    // setTimeout(()=>{ // this will make the execution after the above boolean has changed
    //   this.searchElement.nativeElement.focus();
    // },0);
  }

  public close(): void {
    this.dialogRef.close();
  }

  clickEvent(idValue, idVariante) {
    const indexVariant = this.variants.findIndex(variant => variant._id === idVariante);
    
    const anyDefaultValue = this.variants[indexVariant].values.some(value => value.default === true);
    
    if (anyDefaultValue) {
      this.variants[indexVariant].values.forEach(value => {
        value.default = false;
      });
      for (let i = this.variants.length - 1; i > indexVariant; i--) {
          this.variants.splice(i, 1);
      }
      this.getValidVariants (indexVariant, idValue);
    } else {
      this.getValidVariants (indexVariant, idValue);
    }
  }
  
  getValidVariants (indexVariant, idValue) {

    this.variants[indexVariant].values.forEach(value => {
      if (value._id === idValue) {
        value.default = true;
        if (this.variants.length === this.product.variants.length) {
          this.product.variants = [...this.variants];
          this.validStoke();
        } else {
          this.getVariantsCombination();
        }
      } else {
        value.default = false;
      }
    });

  }
  

  getVariantsCombination() {
    var validVariants = [];
    this.variants.forEach(variant => {
      var val = variant.values.find(element => element.default);
      if (val !== undefined) {
        validVariants.push({ variantId: variant._id, value:val._id})
      }
    })

    if (validVariants.length === this.variants.length) {
      var variantsCombination = {}

      validVariants.forEach(validVariant => {
        variantsCombination[validVariant.variantId] = validVariant.value
      })

      const params = {
        "product": {
          "productId": this.product._id,
          variantsCombination
        }
      }
      this.productsService.variantsCombination(params).subscribe((data:any ) => {
        this.variants = [...this.variants, data.variants];
       
      });
    }
  }

  validStoke() {
    var validVariants = [];
    this.product.variants.forEach(variant => {
      var val = variant.values.find(element => element.default);
      if (val !== undefined) {
        validVariants.push({ variantId: variant._id, value:val._id})
      }
    })

    if (validVariants.length === this.product.variants.length) {
      var variantsCombination = {}

      validVariants.forEach(validVariant => {
        variantsCombination[validVariant.variantId] = validVariant.value
      })

      const params = {
        "product": {
          "productId": this.product._id,
          variantsCombination
        }
      }
      this.ordersService.combination(params).subscribe((data:CombinationArray ) => {
        if (data.combination) {
          this.product.stock = data.combination.stock;
          this.product.price = data.combination.price;
          this.product.oldPrice = data.combination.oldPrice;
        }else{
          this.product.stock = 0;
          this.product.price = 0;
        }
       
      });
    }
  }

  

  // this.ordersService.combination(params).subscribe((data:CombinationArray ) => {
  //   if (data.combination) {
  //     this.product.stock = data.combination.stock;
  //     this.product.price = data.combination.price;
  //     this.product.oldPrice = data.combination.oldPrice;
  //   }else{
  //     this.product.stock = 0;
  //     this.product.price = 0;
  //   }

  // {
  //   "values": [
  //     {
  //       "label": "0.15",
  //       "enabled": true,
  //       "_id": "63bba6d641036a777c1fa268"
  //     },
  //     {
  //       "label": "0.20",
  //       "enabled": true,
  //       "_id": "63bba6e841036a777c1fa288"
  //     }
  //   ],
  //   "_id": "6350a2d68cd112997efe934f",
  //   "label": "Grosor",
  //   "type": "radiobutton"
  // }
}