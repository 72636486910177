<div class="close-btn-outer">
    <button mat-mini-fab color="warn" (click)="close()">
        <mat-icon>close</mat-icon> 
    </button>
</div>

<!-- (min-width: 576px) -->
<div mat-dialog-content fxHide fxShow.gt-xs>
    <div fxLayout="row wrap">
        <div fxFlex="100" fxFlex.gt-sm="50">
            <div class="swiper-container h-100" [swiper]="config">
                <div class="swiper-wrapper">
                    <div *ngFor="let image of product.images" class="swiper-slide">
                        <img [src]="image.medium" class="swiper-lazy" />
                    </div>
                </div>
                <!-- <button mat-icon-button class="swiper-button-prev swipe-arrow">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button mat-icon-button class="swiper-button-next swipe-arrow">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button> -->
            </div>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" ngClass.gt-sm="px-2 m-0" ngClass.sm="mt-2" ngClass.xs="mt-2"
            class="body-dialog">
            <h2>{{product.name}}</h2>
            <div class="py-1 lh">
                <p><span class="text-muted fw-500">{{ 'PRODUCT_DETAIL.CATEGORY' | translate }}: </span><span>{{
                        product.category }}</span></p>
                <p>
                    <span class="text-muted fw-500">{{ 'PRODUCT_DETAIL.AVAILABILITY' | translate }}: </span>
                    <span class="stock stock_negative" *ngIf="product.stock === 0">AGOTADO</span>
                    <span class="stock" *ngIf="product.stock !== 0 && product.enabled">{{product.stock}}</span>
                    <span class="stock" *ngIf="!product.enabled"> No hay disponibles</span>
                </p>
            </div>
            <!-- <div class="py-1">
                <app-rating [ratingsCount]="product.ratingsCount" [ratingsValue]="product.ratingsValue" [direction]="'row'"></app-rating>
            </div> -->
            <p class="py-1 text-muted lh">{{ 'PRODUCT_DETAIL.DESCRIPTION' | translate }}: {{product.description}}</p>
            <!-- <p class="py-1 text-muted lh">{{ 'PRODUCT_DETAIL.SIZE' | translate }}: {{product.medida}}</p> -->
            <!-- <div fxLayoutAlign="end center" class="text-muted">                 
                 <button [mat-dialog-close]="product" mat-icon-button matTooltip="View full details"><mat-icon>arrow_forward</mat-icon></button>               
            </div>               -->
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
                class="details text-muted py-1" *ngFor="let variant of variants">
                <div *ngIf="variant.type === 'color'" class="color">
                    <span class="fw-500">{{ 'PRODUCT_DETAIL.SELECT' | translate }} {{variant.label}}:</span>
                    <button mat-raised-button *ngFor="let value of variant?.values" [style.background]="value.label"
                        (click)="clickEvent(value._id, variant._id)" [disabled]="!value.enabled"
                        [ngClass]="value.default? 'success': ''">&nbsp;</button>
                </div>

                <div *ngIf="variant.type === 'radiobutton'" class="size">
                    <span class="fw-500">{{ 'PRODUCT_DETAIL.SELECT' | translate }} {{variant.label}}:</span>
                    <button mat-raised-button *ngFor="let value of variant?.values"
                        (click)="clickEvent(value._id, variant._id)" [disabled]="!value.enabled"
                        [ngClass]="value.default? 'success': ''">{{value.label}}</button>
                </div>

            </div>
            <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
                class="details text-muted py-1">
                <div *ngIf="product?.curva" class="size" ngClass.xs="mt-1">
                    <span class="fw-500">Select curva:</span>
                    <button mat-raised-button *ngFor="let curva of product?.curva">{{curva}}</button>
                </div>
            </div> -->
            <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
                class="details text-muted py-1">
                <div *ngIf="product?.size" class="size" ngClass.xs="mt-1">
                    <span class="fw-500">Select Grosor:</span>
                    <button mat-raised-button *ngFor="let size of product?.size">{{size}}</button>
                </div>
            </div> -->
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" ngClass.gt-sm="px-2 m-0" ngClass.sm="mt-2" ngClass.xs="mt-2" *ngIf="product.stock !== 0 && product.enabled">
            <div class="divider"></div>
            <h2 class="old-price text-muted"><span *ngIf="product.hasCoupon">${{product.oldPrice | number : '1.2-2'}}</span></h2>
            <h2 class="mt-2 new-price">${{product.price | number : '1.2-2'}}</h2>
            <app-controls [product]="product" [type]="'all'" [origin]="'dialog'" (onCloseProductDialog)="close()">
            </app-controls>
        </div>
    </div>
</div>
<!-- (min-width: 768px)' -->
<div mat-dialog-content fxHide fxShow.xs>
    <div fxLayout="row wrap">
        <div fxFlex="100"  ngClass.gt-sm="px-2 m-0" 
            class="body-dialog">
            <div class="swiper-container h-60" [swiper]="config">
                <div class="swiper-wrapper">
                    <div *ngFor="let image of product.images" class="swiper-slide">
                        <img [src]="image.medium" class="swiper-lazy" />
                    </div>
                </div>
               <!--  <button mat-icon-button class="swiper-button-prev swipe-arrow">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button mat-icon-button class="swiper-button-next swipe-arrow">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button> -->
            </div>
            <h2>{{product.name}}</h2>
            <div class="py-1 lh">
                <p><span class="text-muted fw-500">{{ 'PRODUCT_DETAIL.CATEGORY' | translate }}: </span><span>{{
                        product.category }}</span></p>
                <p>
                    <span class="text-muted fw-500">{{ 'PRODUCT_DETAIL.AVAILABILITY' | translate }}: </span>
                    <span class="stock stock_negative" *ngIf="product.stock === 0">AGOTADO</span>
                    <span class="stock" *ngIf="product.stock !== 0 && product.enabled">{{product.stock}}</span>
                    <span class="stock" *ngIf="!product.enabled"> No hay disponibles</span>
                </p>
            </div>
            <!-- <div class="py-1">
                <app-rating [ratingsCount]="product.ratingsCount" [ratingsValue]="product.ratingsValue" [direction]="'row'"></app-rating>
            </div> -->
            <div class="py-1 lh">
                <p><span class="text-muted fw-500">{{ 'PRODUCT_DETAIL.DESCRIPTION' | translate }}: </span><span>{{product.description}}</span></p>
            </div>
            
            <!-- <p class="py-1 text-muted lh">{{ 'PRODUCT_DETAIL.SIZE' | translate }}: {{product.medida}}</p> -->
            <!-- <div fxLayoutAlign="end center" class="text-muted">                 
                 <button [mat-dialog-close]="product" mat-icon-button matTooltip="View full details"><mat-icon>arrow_forward</mat-icon></button>               
            </div>               -->
            <div id="variants_dialog" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between"
                class="details text-muted py-1" *ngFor="let variant of variants">
                <div *ngIf="variant.type === 'color'" class="color">
                    <span class="fw-500">{{ 'PRODUCT_DETAIL.SELECT' | translate }} {{variant.label}}:</span>
                    <div *ngFor="let value of variant?.values">
                        <button mat-raised-button  [style.background]="value.label"
                        (click)="clickEvent(value._id, variant._id)" [disabled]="!value.enabled"
                        [ngClass]="value.default? 'success': ''">&nbsp;</button>
                    </div>
                    
                </div>

                <div *ngIf="variant.type === 'radiobutton'" class="size">
                    <span class="fw-500">{{ 'PRODUCT_DETAIL.SELECT' | translate }} {{variant.label}}:</span>
                    <div *ngFor="let value of variant?.values">
                        <button mat-raised-button 
                        (click)="clickEvent(value._id, variant._id)" [disabled]="!value.enabled"
                        [ngClass]="value.default? 'success': ''">{{value.label}}</button>
                    </div>
                    
                </div>

            </div>
            <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
                class="details text-muted py-1">
                <div *ngIf="product?.curva" class="size" ngClass.xs="mt-1">
                    <span class="fw-500">Select curva:</span>
                    <button mat-raised-button *ngFor="let curva of product?.curva">{{curva}}</button>
                </div>
            </div> -->
            <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
                class="details text-muted py-1">
                <div *ngIf="product?.size" class="size" ngClass.xs="mt-1">
                    <span class="fw-500">Select Grosor:</span>
                    <button mat-raised-button *ngFor="let size of product?.size">{{size}}</button>
                </div>
            </div> -->
        </div>
       
    </div>
    <div xLayout="row wrap" *ngIf="product.stock !== 0 && product.enabled">
        <div class="divider"></div>
        <h2 class="mt-2 new-price">${{product.price}}</h2>
        <app-controls [product]="product" [type]="'all'" [origin]="'dialog'" (onCloseProductDialog)="close()">
        </app-controls>
    </div>
</div>