<div id="app" class="h-100 app" 
                [ngClass]="settings.theme" 
                [class.main-toolbar-fixed]="settings.mainToolbarFixed"
                [dir]="(settings.rtl) ? 'rtl' : 'ltr'"> 
    <router-outlet></router-outlet> 
    
    
    <script id="CookieDeclaration" src="https://consent.cookiebot.com/4c2bd876-7f64-4e9c-9372-f2410be48523/cd.js"
      type="text/javascript" async></script>
    <ngx-spinner bdColor="rgba(51,51,51,0.7)" size="large" color="#fff" type="ball-clip-rotate"></ngx-spinner>     
</div>