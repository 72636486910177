import { Component, OnInit, HostListener, ViewChild, Inject, PLATFORM_ID } from '@angular/core'; 
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
import { AppService } from '../app.service';
import { Category, Catalog, ResPost, Ordenes, Catalog_values } from '../app.models';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { isPlatformBrowser } from '@angular/common';
import { CatalogsService } from './../services/catalogs.service';
import { FormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { OrdersService } from './../services/orders.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [ SidenavMenuService ]
})
export class PagesComponent implements OnInit {
  public showBackToTop:boolean = false; 
  public categories:Category[];
  public category:Category;
  public sidenavMenuItems:Array<any>;
  public form: UntypedFormGroup;
  @ViewChild('sidenav', { static: true }) sidenav:any;
  public settings: Settings;
  total = [];
  grandTotal = 0;
  subTotal = 0;
  cartItemCount = [];
  cartItemCountTotal = 0;
  @ViewChild('searchMenuTrigger') searchMenuTrigger!: MatMenuTrigger;
  constructor(public appSettings:AppSettings, 
              public appService:AppService, 
              public sidenavMenuService:SidenavMenuService,
              public catalogsService:CatalogsService,
              public router:Router,
              public fb: UntypedFormBuilder,
              public ordersService: OrdersService, 
              public snackBar: MatSnackBar,
              @Inject(PLATFORM_ID) private platformId: Object) { 
    this.settings = this.appSettings.settings; 
  }

  ngOnInit() {
    this.getCategories();
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
    setTimeout(() => {
      this.settings.theme = 'black'; 
      // this.getMontos();
    }, 2000);
    this.form = this.fb.group({
      search_value: new FormControl()
    });

    this.form.controls.search_value.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.search();
    });
  } 
  
  public getCategories(){  
    if(this.appService.Data.categories.length == 0) { 
      console.log('servicio pages')
      this.catalogsService.getCatalogsByLabel('Categorias').subscribe((data:Catalog) => {
        this.categories = data['catalog'][0].values; ;
        this.appService.Data.categories = data['catalog'][0].values; ;
      });
    }
    else{
      console.log('consulta pages')
      this.categories = this.appService.Data.categories;
    }
  }

  public changeCategory(event){
    if(event.target){
      this.category = this.categories.filter(category => category.name == event.target.innerText)[0];
    }
    if(window.innerWidth < 960){
      this.stopClickPropagate(event);
    } 
  }

  // public remove(product) {

  //   let  variantsCombination =  product.variantsCombination 
  //   const params = {
  //     "product": {
  //       "productId": product._id,
  //       "quantity": 0,
  //        variantsCombination
  //     }
  //   }
    
  //   this.ordersService.addOrdersProduct(params).subscribe((data: ResPost) => {
  //     if (data.mensaje === 'Error 200--OK') {
  //       this.getOrders();
  //     } else {
  //       this.snackBar.open(this.translate.instant('ERROR_TRY_AGAIN'), '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
  //       return false;
  //     }

  //   });
  // }

  // public getOrders () {
  //   this.ordersService.getOrdersBytoken().subscribe((data: Ordenes) => {
  //     if(data.order.products){
  //       this.appService.Data.cartList = [];
  //       this.appService.Data.cartList = data.order.products;
  //       this.appService.Data.totalCartCount = data.order.quantity;
  //       this.appService.Data.totalPrice = data.order.amount;
  //       this.appService.Data.shippingPercentage = data.order.shippingPercentage;
  //       this.appService.Data.isFreeShipping = data.order.isFreeShipping;
  //       this.appService.Data.remainingShippingAmount = data.order.remainingShippingAmount;
  //       this.getMontos();
  //     }
  //     this.appService.Data.order = data.order;
  //   });
  // }

  // getMontos () {
  //   this.appService.Data.cartList.forEach(product => {
  //     this.total[product.id] = product.quantity * Number(product.price);
  //     this.grandTotal += product.quantity * Number(product.price);
  //     this.cartItemCount[product.id] = product.quantity;
  //     this.cartItemCountTotal += product.quantity;
  //   })

  // }

  // public clear() {
  //   this.ordersService.removeOrdersProduct().subscribe((data: ResPost) => {
  //     if (data.mensaje === 'Error 200--OK') {
  //       this.getOrders();
  //     } else {
  //       this.snackBar.open(this.translate.instant('ERROR_TRY_AGAIN'), '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
  //       return false;
  //     }
  //   });
  // }
 

  // public changeTheme(theme){
  //   this.settings.theme = theme;       
  // }

  public stopClickPropagate(event: any){
    event.stopPropagation();
    event.preventDefault();
  }

  public search(){
    let value=this.form.controls.search_value.value.toUpperCase();
    this.router.navigate(['/products', value]);
    this.searchMenuTrigger.closeMenu();
  }
 
  public scrollToTop(){
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    var scrollInterval = setInterval(()=>{
      if(window.pageYOffset != 0){
         window.scrollBy(0, scrollStep);
      }
      else{
        clearInterval(scrollInterval); 
      }
    },10);
    if(window.innerWidth <= 768){
      setTimeout(() => { 
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0,0);
        }  
      });
    }
  }
  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll($event) {
  //   const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);   
  //   let header_toolbar = document.getElementById('header-toolbar'); 
  //   if(header_toolbar){ 
  //     if(scrollTop >= header_toolbar.clientHeight) {
  //       this.settings.mainToolbarFixed = true;
  //     }
  //     else{
  //       if(!document.documentElement.classList.contains('cdk-global-scrollblock')){
  //         this.settings.mainToolbarFixed = false;
  //       }        
  //     } 
  //   } 
  //   else{
  //     this.settings.mainToolbarFixed = true;
  //   }  
  //   ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;  
  // }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) { 
        this.sidenav.close(); 
      }                
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus(){
    if(window.innerWidth < 960){
      this.sidenavMenuService.closeAllSubMenus();
    }    
  }

  public openCart(){
    this.router.navigate(['/cart']);
  }
  public openLogin(){
    this.appService.rolUser();
  }
  
  // public updateCart(){
  //   this.appService.getOrder();
  // }

}