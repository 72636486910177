<div fxLayout="row" fxLayout.xs="column" [fxLayoutAlign]="align" class="text-muted">
    <!-- <div *ngIf="this.product.stock === 0 && type!='wish'" class="bg-warn p-1 mt-2 alert">
        Esté producto no está disponible.
    </div> -->
    
   <!--  <div *ngIf="banVariants && type!='wish'" class="bg-warn p-1 mt-2 alert">
        Selecciona todas las opciones.
    </div> -->
    <div *ngIf="product?.stock > 0 && (type=='all' || type=='wish')">
        <bdi>
            <span *ngIf="type!='wish'" class="fw-500">{{ 'MSG_QUANTITY' | translate }}:</span> 
            <button mat-icon-button matTooltip="{{ 'BTN.REMOVE' | translate}}" (click)="decrement()" [disabled]="spinner"><mat-icon>remove</mat-icon></button>
            <span> {{count}} </span>
            <button mat-icon-button matTooltip="{{ 'BTN.ADD' | translate }}" (click)="increment()" [disabled]="spinner"><mat-icon>add</mat-icon></button> 
        </bdi>
    </div>
    <div *ngIf="type!='wish'">
        <!-- <button mat-icon-button matTooltip="Add to wishlist" (click)="addToWishList(product)"><mat-icon>favorite</mat-icon></button>
        <button class="btn_add_cart" mat-raised-button color="primary" matTooltip="{{ 'MSG_ADD_CART' | translate }}" *ngIf="product?.stock > 0" (click)="openProductDialog(product)">{{ 'MSG_ADD_CART' | translate }}</button>
        <button mat-icon-button matTooltip="Add to compare" (click)="addToCompare(product)"><mat-icon>compare</mat-icon></button>
        <button *ngIf="type!='all'" mat-icon-button matTooltip="Quick view" (click)="openProductDialog(product)"><mat-icon>remove_red_eye</mat-icon></button> -->

        <!-- <button mat-icon-button matTooltip="Add to wishlist" (click)="addToWishList(product)"><mat-icon>favorite</mat-icon></button> -->
        <!-- <button mat-icon-button matTooltip="Add to cart" *ngIf="product?.stock > 0" (click)="addToCart(product)"><mat-icon>shopping_cart</mat-icon></button> -->
        <button class="btn_add_cart" mat-raised-button color="primary" matTooltip="{{ 'MSG_ADD_CART' | translate }}" *ngIf="origin === 'dialog'" (click)="addToCart(product)">{{ 'MSG_ADD_CART' | translate }}</button>
        <button class="btn_add_cart close_" mat-raised-button color="primary" matTooltip="{{ 'MSG_ADD_CART' | translate }}" *ngIf="origin === 'dialog'" (click)="closeProductDialog(product)">{{ 'CLOSE' | translate }}</button>
        <button class="btn_add_cart" mat-raised-button color="primary" matTooltip="{{ 'MSG_ADD_CART' | translate }}" *ngIf="origin !== 'dialog'" (click)="openProductDialog(product)">{{ 'MSG_ADD_CART' | translate }}</button>
        <!-- <button mat-icon-button matTooltip="Add to compare" (click)="addToCompare(product)"><mat-icon>compare</mat-icon></button>
        <button *ngIf="type!='all'" mat-icon-button matTooltip="Quick view" (click)="openProductDialog(product)"><mat-icon>remove_red_eye</mat-icon></button> -->
    </div>
    <div  fxLayout="column" fxLayoutAlign="center center" class="user-spinner" *ngIf="product?.stock > 0 && (type=='all' || type=='wish')">   
        <mat-spinner color="primary" [diameter]="50" *ngIf="spinner"></mat-spinner>    
    </div>
</div> 
